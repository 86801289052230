.App {
    
    background: white;
    color: gray;
    height: 20vh;
    box-sizing: border-box;
  }
  
  .react-tabs {
    display: flex;
    margin-left: 0px;
    /* width: 1070px;
    height: 400px; */
    color: black;
    background: white;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0;
    padding: 0;
    color: black;
    background: white;
  }
  
  .react-tabs__tab {
   
    list-style: none;
    padding: 10px 5px;
    cursor: pointer;
    color: rgb(17, 16, 16);
  }
  
  .react-tabs__tab--selected {
    background: rgb(136, 236, 253);
    border-color: #1c1d1f;
    
    color: black;
  }
  
  .react-tabs__tab-panel {
    display: none;
    width: 950px;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  .react-tabs__tab {
    padding-left: 17px;
  }
  
  .react-tabs__tab--selected {
    padding-left: 17px;
  }
  
  .panel-content {
    text-align: center;
  }
  