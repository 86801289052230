.statistics {
    margin-bottom: 20px;
  }
  
  .item-card {
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  
  .icon-circle {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .item-card .MuiSvgIcon-root {
    font-size: 2rem;
  }
  
  .item-card p {
    color: #888;
    margin-bottom: 10px;
  }
  
  .item-card h5 {
    color: #333;
  }
  
  




  .tickets-table {
    margin-top: 20px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .tickets-table th, .tickets-table td {
    text-align: left;
    padding: 16px;
  }
  
  .tickets-table th {
    background-color: #f5f5f5;
  }
  
  .tickets-table .MuiIconButton-root {
    color: #333;
  }
  