body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url("https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.240/static/media/default_left.e74de3ec.svg"), url("https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.240/static/media/default_right.6ece9751.svg");
  background-repeat: no-repeat;
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-size: 368px, 368px;
  background-position: left bottom, right bottom; */
  background-color: rgb(250, 251, 252);
  font-family: 'Open sans';
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

#form-dialog-title, #form-dialog-title1, #form-dialog-title2, #form-dialog-title3{
  background-color: rgb(19, 62, 103);
  color: white;
}


.MuiAppBar-colorPrimary {
   color: #fff; 
   background-color:rgb(106,207,246) !important; 
}