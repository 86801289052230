
@font-face {
    font-family: 'dicotbold';
    src: local('DicotBold-Bold'),
    url('./assets/fonts/DicotBold-Bold.ttf');
}

.flex-container{
    margin: 5px;
    height: 100px;
    box-shadow: #242423 4px;
    display: inline-block;
    height: 269px;
}


.flex-container1{
    background: repeating-linear-gradient(120deg,#fff 0px,#fff 450px,rgb(255, 242, 0)250px,rgb(232, 233, 173));
    border-left: white;
    padding: 20px;
}
/*
.flex-container2{
    background: repeating-linear-gradient(-120deg,#fff 0px,#fff 450px,rgb(106, 207, 246)250px,rgb(180, 240, 248));
    border-left: white;
    padding: 20px;
}

.flex-container3{
    background: repeating-linear-gradient(120deg,#fff 0px,#fff 450px,rgb(19, 62, 103)250px,rgb(186, 215, 241));
    border-left: white;
    padding: 20px;
}

.flex-container4{
    background: repeating-linear-gradient(-120deg,#fff 0px,#fff 450px,rgb(255, 242, 0)250px,rgb(232, 233, 173));
    border-left: white;
    padding: 20px;
} */

#main{
    margin: 0 auto;
    width: 1000px;
}

section{
    /* position: sticky; */
    position: relative;
    width: 100%;
    height: 30%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 50px;
    top: 0;
    z-index: 1;
}
section::before{
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100vh;
    height: 100vh;
    background: linear-gradient(to bottom,rgb(186, 215, 241) 10%,rgb(106, 207, 246) 20%);
    border-radius: 50%;
    transform-origin: bottom;
    transform: scale(7);
}
.content{
    position: relative;
    /* z-index: 1; */
    text-align: center;
}

.content h1{
    font-family: 'Open sans';
    font-weight: bold;
}