
.App {
  /*text-align: center;*/
  height: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.business {
  display:none;
}
.business1 {
  display:none;
}
.aligneleft{
  
  text-align: left;
  float:left;
}
.alignecenter{
  
  text-align: center;
  float:center;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  
  font-weight: bold !important;
}



.react-tel-input .form-control{
  width: 256px !important;
  height: 40px !important;
}

.react-tel-input .country-list{
  text-align: left;
  width: 256px !important;
}
/* .MuiTypography-root {
  margin: auto !important;
} */
.buttonEnregistrer{
  float: right;
  width: 83%;
  margin-right: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  height:625px;
  overflow:auto;
}
th{
  background-color: #c5c5c5;
  font-weight: bold !important;
}

.popoverHelp{
  padding: 10px;
}

.react-calendar-timeline .rct-header-root{
  background: gray !important;
}

.declinedRow{
  background-color:crimson;
}

.timelineSticky {
  position: sticky;
  top: 16vh;
  z-index: 2000;
}

.rct-sidebar{
  width: 38vh;
}
.rct-sidebar div{
  width: 38vh;
}
.divHeaderName{
  width: 40vh important;
}



